import { useTranslation } from 'react-i18next';
const MyInfo = () => {
  const { t, i18n } = useTranslation();
    return (
        <div className="text-black dark:text-white mt-10">
            <h1>{t("hurawatch Watch Series")}</h1>
            <p className="mb-10 max-w text-sm/6 text-black dark:text-white">
                {t("Watch entire seasons of popular")}
            </p>
            <h1>{t("hurawatch movies just video streaming")}</h1>
            <p className="mb-10 max-w text-sm/6 text-black dark:text-white">
                {t("There is no requirement for any account registration")}
            </p>
            <h1>{t("hura watch source contents")}</h1>
            <p className="mb-10 max-w text-sm/6 text-black dark:text-white">
                {t("Hurawatch are available platforms")}
            </p>
            <h1>{t("Is hurawatch Safe to Use?")}</h1>
            <p className="mb-10 max-w text-sm/6 text-black dark:text-white">
                {t("Online security is a significant concern")}
            </p>
            <h1>{t("Why Use WatchSeries to Watch Series and Movies for Free?")}</h1>
            <p className="mb-10 max-w text-sm/6 text-black dark:text-white">
                {t("Why Use hurawatch to Watch Series and Movies for Free? Description")}
            </p>
        </div>)
}

export default MyInfo