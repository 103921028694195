import React from "react";
import { useTranslation } from "react-i18next";

const LinkLeftNavMenuItem = ({ text, icon, className, action }) => {
  const { t } = useTranslation();
  return (
    <a href={action} rel="menu-link"
      className={
        "flex items-center h-10 px-3 text-white md:text-black dark:text-white text-sm cursor-pointer mb-[1px] rounded-lg hover:bg-white/[0.15] md:hover:bg-black/[0.15] dark:hover:bg-white/[0.15] " +
        className
      }
    >
      <span className="text-xl mr-5">{icon}</span>
      {t(text)}
    </a>
  );
};

export default LinkLeftNavMenuItem;
