import React from "react";

import { AiFillHome, AiOutlineFlag } from "react-icons/ai";
import { MdExplore } from "react-icons/md";
// import { CgMusicNote } from "react-icons/cg";
// import { FiFilm } from "react-icons/fi";
import { ImNewspaper } from "react-icons/im";
import { HistoryIcon } from "lucide-react";
// import { GiDiamondTrophy, GiHanger } from "react-icons/gi";
// import { RiLightbulbLine, RiFeedbackLine } from "react-icons/ri";
// import { FiSettings, FiHelpCircle } from "react-icons/fi";
// import { IoLogoJavascript } from "react-icons/io";
// import { FaReact } from "react-icons/fa";
// import { SiYoutubegaming } from "react-icons/si";

export const Tags = {
  AllCategory: "All Category",
  AllGenre: "All Genre",
  AllCountry: "All Country",
  AllYear: "All Year",
  AllTag: "All Tag"
}

export const GroupTag = {
  Category: "Category",
  Genre: "Genre",
  Country: "Country",
  Year: "Year"
}

export const menuType = {
  Button: "button",
  Link: "link"
}


export const Sorts = [
  {
    label: "Release Date",
    ordering: "desc",
    sortBy: "release_date"
  },
  {
    label: "Popular",
    ordering: "desc",
    sortBy: "views"
  },
  {
    label: "Upload Date",
    ordering: "desc",
    sortBy: "add_date"
  },
  {
    label: "Title",
    ordering: "asc",
    sortBy: "title"
  },
]

export const SortLabels = [
  Sorts[0].label,
  Sorts[1].label,
  Sorts[2].label,
  Sorts[3].label,
]

export const MediaActions={
  WatchList :"WatchList",
  WatchHistory:"WatchHistory"
}

export const AllMediaActions=[
  MediaActions.WatchHistory,
  MediaActions.WatchList
]

export const CommentTypes={
  Media:"Media",
  General : "General"
}

export const categories = [
  { name: "Home", icon: <AiFillHome />, type: menuType.Button, link: "/" },
  { name: "Explore", icon: <MdExplore />, type: menuType.Button, link: "/search" },
  { name: "Watch History", icon: <HistoryIcon />, type: menuType.Button, link: `/search?show=` + MediaActions.WatchHistory },
  { name: "Blogs", icon: <ImNewspaper />, type: menuType.link, link: "/blogs" },
  { name: "About Us", icon: <ImNewspaper />, type: menuType.link, link: "/about-us" },
  // { name: "Trending", icon: <MdLocalFireDepartment />, type: "category" },
  // { name: "JavaScript", icon: <IoLogoJavascript />, type: "category" },
  // { name: "ReactJS", icon: <FaReact />, type: "category" },
  // { name: "Music", icon: <CgMusicNote />, type: "category" },
  // { name: "Films", icon: <FiFilm />, type: "category" },
  // { name: "Live", icon: <MdLiveTv />, type: "category" },
  // { name: "Gaming", icon: <SiYoutubegaming />, type: "category" },
  // { name: "News", icon: <ImNewspaper />, type: "category" },
  // { name: "Sports", icon: <GiDiamondTrophy />, type: "category" },
  // { name: "Learning", icon: <RiLightbulbLine />, type: "category" },
  // {
  //   name: "Fashion & beauty",
  //   icon: <GiHanger />,
  //   type: "category",
  //   divider: true,
  // },
  // { name: "Settings", icon: <FiSettings />, type: "menu" },
  // { name: "Report History", icon: <AiOutlineFlag />, type: "menu" },
  // { name: "Help", icon: <FiHelpCircle />, type: "menu" },
  // { name: "Send feedback", icon: <RiFeedbackLine />, type: "menu" },
];