import ReactGA from 'react-ga4';

export const SendGA = () => {
  ReactGA.send({
    hitType: "pageview",
    page: document.title,
    title: document.title
  })
}

export const SendNewActionGA = (category,action) => {
  ReactGA.event({
    category: category,
    action: action,
    label: document.title,
  });
}
