import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { fetchDataFromApi } from "../utils/api";
import { DataContext } from "../context/contextApi";
import LeftNav from "../components/LeftNav";
import UseOnline from "../utils/UseOnline";
import OfflineComponent from "../shared/OfflineComponent";
import VideoCard from "../components/VideoCard";
import ShimmerVideoCard from "../shared/ShimmerVideoCard";
import InifiniteScroll from "../shared/InfiniteScroll";
import TagPills from "../components/TagPills";
import { Blockquote } from "flowbite-react";
import { AllMediaActions, GroupTag, MediaActions, SortLabels, Sorts, Tags } from "../utils/constants";
import { SendGA } from "../utils/reactGAWrapper";
import { getMediaActions } from "../utils/MediaActionUtil";

const SearchResult = () => {
  const [result, setResult] = useState([]);
  const { setLoading } = useContext(DataContext)
  const [isSlimLoading, setSlimLoading] = useState(false);
  const isOnline = UseOnline();
  const [searchParams] = useSearchParams();
  const searchQuery = searchParams.get('q')
  const searchTag = searchParams.get('t')
  const searchExcludedTag = searchParams.get('et')
  const groupDisplayType = searchParams.get('gtt')
  const groupDisplayGenre = searchParams.get('gtg')
  const groupDisplayCountry = searchParams.get('gtc')
  const groupDisplayYear = searchParams.get('gty')
  const show = searchParams.get('show')
  const sort_by = searchParams.get('sort_by')

  const [categories, setCategories] = useState([])
  const [genres, setGenres] = useState([])
  const [countries, setCountries] = useState([])
  const [year, setYears] = useState([])
  const [tags, setTags] = useState([])

  const [selectedCategory, setSelectedCategory] = useState("")
  const [selectedGenres, setSelectedGenres] = useState("")
  const [selectedCountries, setSelectedCountries] = useState("")
  const [selectedYears, setSelectedYears] = useState("")
  const [selectedTag, setSelectedTag] = useState("")
  const [selectedSortLabel, setSelectedSortLabel] = useState("")

  const [nextApi, setNextApi] = useState("")
  const navigate = useNavigate();

  useEffect(() => {
    if (groupDisplayType != null)
      setSelectedCategory(groupDisplayType)
    else
      setSelectedCategory(Tags.AllCategory)

    if (groupDisplayYear != null)
      setSelectedYears(groupDisplayYear)
    else
      setSelectedYears(Tags.AllYear)

    if (groupDisplayGenre != null)
      setSelectedGenres(groupDisplayGenre)
    else
      setSelectedGenres(Tags.AllGenre)

    if (groupDisplayCountry != null)
      setSelectedCountries(groupDisplayCountry)
    else
      setSelectedCountries(Tags.AllCountry)

    if (sort_by != null) {
      setSelectedSortLabel(sort_by)
    }
    else
      setSelectedSortLabel(SortLabels[0])

    if (searchTag != null && searchTag != "") {
      setSelectedTag(searchTag)
      setTags([Tags.AllTag, searchTag])
    }

    document.getElementById("root").classList.remove("custom-h");
    if (!AllMediaActions.some(s => s == show)) {
      fetchTags('Type', 'Category', setCategories)
      fetchTags('Genre', 'Genre', setGenres)
      fetchTags('Country', 'Country', setCountries)
      fetchTags('Year', 'Year', setYears)
    }

    SendGA()
  }, [])

  useEffect(() => {
    if (isSlimLoading == true) return
    setResult([])
    fetchSearchResults();
  }, [searchQuery, searchExcludedTag, groupDisplayType, groupDisplayCountry, groupDisplayGenre, groupDisplayYear, searchTag, sort_by]);

  useEffect(() => {
    if (selectedCategory == "" && selectedGenres == "" && selectedCountries == "" && selectedYears == "" && selectedTag == "") return
    var queryTags = ''
    if (selectedCategory != "" && selectedCategory != Tags.AllCategory)
      queryTags += `&gtt=${selectedCategory}`
    if (selectedGenres != "" && selectedGenres != Tags.AllGenre)
      queryTags += `&gtg=${selectedGenres}`
    if (selectedCountries != "" && selectedCountries != Tags.AllCountry)
      queryTags += `&gtc=${selectedCountries}`
    if (selectedYears != "" && selectedYears != Tags.AllYear)
      queryTags += `&gty=${selectedYears}`
    if (selectedTag != "" && selectedTag != Tags.AllTag)
      queryTags += `&t=${searchTag}`

    if (searchQuery == null) {
      var navigateUrl = `/search?page=1${queryTags}`
      if (show) {
        navigateUrl += `&show=${show}`
      }
      if (selectedSortLabel) {
        navigateUrl += `&sort_by=${selectedSortLabel}`
      }
      navigate(navigateUrl);
    }
    else {
      var navigateUrl = `/search?q=${searchQuery}${queryTags}`
      if (selectedSortLabel) {
        navigateUrl += `&sort_by=${selectedSortLabel}`
      }
      console.log('navigateUrl',navigateUrl)
      navigate(navigateUrl);
    }

  }, [selectedCategory, selectedGenres, selectedCountries, selectedYears, selectedTag, show, selectedSortLabel])

  const fetchSearchResults = async () => {
    if (AllMediaActions.some(s => s == show)) {
      setResult(getMediaActions(show, 0, true))
      setSlimLoading(false)
      setLoading(false)
    }
    else {
      setSlimLoading(true)
      const data = await fetchDataFromApi(getQueryString());
      setSlimLoading(false)
      setLoading(false)
      setResult((prevItems) => [...prevItems, ...data?.results]);
      setNextApi(data?.next)
    }
  };

  const getQueryString = () => {
    var queryString = ''
    queryString = `/api/v1/search?page=${1}`
    if (searchQuery != null && searchQuery != '') {
      queryString += `&q=${searchQuery}`
    }
    if (searchTag != null) {
      queryString += `&t=${searchTag}`
    }
    if (searchExcludedTag != null) {
      queryString += `&et=${searchExcludedTag}`
    }
    if (groupDisplayCountry != null) {
      queryString += `&tags=${groupDisplayCountry}`
    }
    if (groupDisplayGenre != null) {
      queryString += `&tags=${groupDisplayGenre}`
    }
    if (groupDisplayType != null) {
      queryString += `&tags=${groupDisplayType}`
    }
    if (groupDisplayYear != null) {
      queryString += `&tags=${groupDisplayYear}`
    }
    if (sort_by) {
      const querySort = Sorts.find(s => s.label == sort_by)
      queryString += `&ordering=${querySort.ordering}&sort_by=${querySort.sortBy}`
    }
    if (show) {
      queryString += `&show=${show}`
    }
    //no any tags
    // if (queryString == `/api/v1/search?page=${1}`) {
    //   queryString = `/api/v1/media?page=${1}`
    //   if (show) {
    //     queryString += `&show=${show}`
    //   }
    // }
    return queryString
  }

  const fetchNextSearchResults = async () => {
    const data = await fetchDataFromApi(nextApi);
    setResult((prevItems) => [...prevItems, ...data?.results]);
    setNextApi(data?.next)
  };

  const fetchTags = async (groupDisplay, label, setResult) => {
    const data = await fetchDataFromApi(`/api/v1/tags?=${1}&type=${groupDisplay}`);
    setResult((prevItems) => [`All ${label}`, ...data?.results.map(s => s.title)]);
  };

  return (
    <div className="flex h-[calc(100%-56px)]">
      <LeftNav />

      {!isOnline && (
        <div className="grow w-[calc(100%-240px)] h-full overflow-y-auto bg-black">
          <OfflineComponent />
        </div>
      )}
      {isOnline && (
        <div className="grow w-[calc(100%-240px)] h-full overflow-y-auto bg-white dark:bg-black">
          {show ? <h1 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white mx-5">Your {show}</h1> : ""}
          <InifiniteScroll resultComponents={
            <>
              {
                searchTag == null || searchTag == "" ? "" :
                  <div className='sticky pl-5 pt-4 z-1'>
                    <TagPills result={tags} id={"FilterTag"} onSelect={setSelectedTag} selectedItem={selectedTag}>
                    </TagPills>
                  </div>
              }
              {categories.length == 0 ? "" :
                <div className='sticky pl-5 pt-4 z-1'>
                  <TagPills result={categories} id={"Category"} onSelect={setSelectedCategory} selectedItem={selectedCategory}>
                  </TagPills>
                </div>}
              {
                genres.length == 0 ? "" : <div className='sticky pl-5 pt-4 z-1'>

                  <TagPills result={genres} id={"Genre"} onSelect={setSelectedGenres} selectedItem={selectedGenres}>
                  </TagPills>
                </div>
              }

              {
                countries.length == 0 ? "" : <div className='sticky pl-5 pt-4 z-1'>
                  <TagPills result={countries} id={"Country"} onSelect={setSelectedCountries} selectedItem={selectedCountries}>
                  </TagPills>
                </div>
              }
              {
                year.length == 0 ? "" :
                  <div className='sticky pl-5 pt-4 z-1'>
                    <TagPills result={year} id={"Year"} onSelect={setSelectedYears} selectedItem={selectedYears}>
                    </TagPills>
                  </div>
              }
              {
                !AllMediaActions.some(s=>s==show) ?
                  <div className='sticky pl-5 pt-4 z-1'>

                    <TagPills result={SortLabels} id={"Sorting"} onSelect={setSelectedSortLabel} selectedItem={selectedSortLabel}>
                    </TagPills>
                  </div> : ""
              }

              <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 p-5">
                {/* When result is blank means data is not fetched yet meanwhile that time show Shimmer UI 
               when data fetching is done that means we get result data then show the data in the dom*/}
                {result.length == 0 && isSlimLoading == false ?
                  <Blockquote>Filters not found</Blockquote>
                  : (isSlimLoading == true
                    ? Array(20)
                      .fill("")
                      .map((e, index) => {
                        return <ShimmerVideoCard key={index} />;
                      }) :
                    result?.map((item, index) => {
                      return (
                        <VideoCard key={index} video={item} />
                        // i allways prefered to pass unique key that i got from the api and that is standered way also but for this api some unique key like videoId present twice and more in the searchResults, i don't khow why. So, forcefully i choose map index :(
                      );
                    })
                  )}
              </div>
            </>
          }
            fetchSearchResults={fetchNextSearchResults}
            nextAPIUrl={nextApi}
          >

          </InifiniteScroll>
        </div>)}
    </div>
  );
};

export default SearchResult;
