// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getFirestore} from "@firebase/firestore"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCgPB6UFuaA34NQ4aAZ6W_VXAyuaQCH_7Q",
  authDomain: "khflix-history.firebaseapp.com",
  databaseURL: "https://khflix-history-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "khflix-history",
  storageBucket: "khflix-history.appspot.com",
  messagingSenderId: "520576414227",
  appId: "1:520576414227:web:2c896672bc77ac16fb85e6",
  measurementId: "G-SJDL7JS6R7"
};

// Initialize Firebase
export const firebaseApp  = initializeApp(firebaseConfig);
export const db = getFirestore(firebaseApp)
