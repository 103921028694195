import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { BASE_URL, cardThumbnail, fetchDataFromApi, fetchRawData, getAccessToken } from "../utils/api";
import { DataContext } from "../context/contextApi";
import SuggestionVideoCard from "../components/SuggestionVideoCard";
import ShimmerSuggestionVideoCard from "../shared/ShimmerSuggestionVideoCard";
import { useSearchParams } from "react-router-dom";
import { Accordion, Pagination, Select } from "flowbite-react";
import GroupTags from "../components/GroupTags";
import EpisodeList from "../components/EpisodeList";
import ReactHlsPlayer from 'react-hls-player';
import { SendGA } from "../utils/reactGAWrapper";
import SocialShareButton from "../components/SocialShareButton";
import WatchListButton from "../components/WatchListButton";
import { addAction } from "../utils/MediaActionUtil";
import { CommentTypes, MediaActions } from "../utils/constants";
import Discussion from "../components/Discussion";
import { useTranslation } from "react-i18next";
// import { CommentCount, DiscussionEmbed } from 'disqus-react';


const VideoDetails = () => {
  const [video, setVideo] = useState();
  const [relatedVideos, setRelatedVideos] = useState([]);
  const [episodes, setEpisodes] = useState([]);
  const [seasons, setSeasons] = useState([]);
  const [tags_info, setTagsInfo] = useState([]);
  const [searchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(0);
  const [currentSeason, setCurrentSeason] = useState(0);
  const [subTitles, setSubtitles] = useState([])

  const id = searchParams.get('m')
  const { setLoading } = useContext(DataContext);
  const { t } = useTranslation();

  useEffect(() => {
    document.getElementById("root").classList.add("custom-h");
    fetchVideoDetails();
    window.scrollTo(0, 0)
    //fetchRelatedVideos();
  }, [id]);

  useEffect(() => {
    if (currentPage == 0 || currentSeason == 0) return;
    fetchEpisodes()
  }, [currentPage, currentSeason, id])

  const createSubtitles = async (groupby_sub) => {
    var currentSubtitles = Object.entries(groupby_sub).map(([key, value]) => {
      return {
        src: value[0].src,
        label: key,
        srclang: value[0].srclang
      }
    });
    var newSubTitles = []
    for (var i = 0; i < currentSubtitles.length; i++) {
      const value = currentSubtitles[i]
      var rawData = await fetchRawData(value.src)
      if (rawData?.data != null) {
        var vttText = rawData?.data
        if (!value.src.includes(".vtt")) {
          var srtRegex = /(.*\n)?(\d\d:\d\d:\d\d),(\d\d\d --> \d\d:\d\d:\d\d),(\d\d\d)/g;
          vttText = 'WEBVTT\n\n' + rawData?.data.replace(srtRegex, '$1$2.$3.$4');
        }
        var vttBlob = new Blob([vttText], { type: 'text/vtt' });
        var blobURL = URL.createObjectURL(vttBlob);

        newSubTitles.push({
          src: blobURL,
          srclang: value.srclang,
          label: value.srclang,
        })
      }
    }
    setSubtitles(newSubTitles)
  }
  // fetch Data for that perticular video
  const fetchVideoDetails = async () => {
    setLoading(true);
    const data = await fetchDataFromApi(`/api/v1/media/${id}`);
    if (data.subtitles_info != null) {
      const groupby_sub = data.subtitles_info.reduce((ob, item) => ({ ...ob, [item.label]: [...ob[item.label] ?? [], item] }), {})
      createSubtitles(groupby_sub)
    }
    setVideo(data);
    setTagsInfo(data.tags_info)
    setCurrentPage(data.page_number)
    setCurrentSeason(data.season)
    setSeasons(data.season_ids)
    fetchRelatedVideos(data.tags_info)
    setLoading(false);
    SendGA();
    addAction(data, MediaActions.WatchHistory,false)
  };

  const fetchEpisodes = async () => {
    setLoading(true);
    const data = await fetchDataFromApi(`/api/v1/media/${id}/episodes?page=${currentPage}&season=${currentSeason}`);
    setEpisodes(data);
    setLoading(false);
  };

  // fetch Data for the suggestion videos
  const fetchRelatedVideos = async (ts) => {
    var queryString = `/api/v1/search?page=${1}`

    ts.map((item) => {
      {
        item?.map((tag, index) => {
          if (tag.group_display == 'Country' || tag.group_display == 'Genre' || tag.group_display == 'Type')
            queryString += `&tags=${tag.title}`
          return tag
        })
        return item
      }
    })
    const data = await fetchDataFromApi(queryString);
    setRelatedVideos(data.results);
  };

  const onPageChange = (page) => {
    setCurrentPage(page)
  };

  const onChangeSeason = (event) => {
    setCurrentSeason(event.target.value)
  }

  return (
    <div className="flex flex-row justify-center h-[calc(100%-56px)] bg-white dark:bg-black">
      <div className="w-full max-w-[1280px] flex flex-col lg:flex-row ">
        <div className="flex flex-col lg:w-[calc(100%-350px)] xl:w-[calc(100%-400px)] px-4 py-3 lg:py-6 overflow-y-auto">
          <div className="ml-[-16px] lg:ml-0 mr-[-16px] lg:mr-0">
            {/* Notice we turned off controls? We're supplying our own, so we hide the native ones. */}
            {video == null ? <></> :
              <ReactHlsPlayer
                crossOrigin="true"
                src={`${BASE_URL}${video?.hls_info.master_file}?access_token=${getAccessToken()}`}
                autoPlay={false}
                controls={true}
                poster={cardThumbnail(video?.poster_url, 350)}
                className="w-full aspect-video bg-black	"
              //src="https://live-par-2-cdn-alt.livepush.io/live/bigbuckbunnyclip/index.m3u8"
              >
                {
                  subTitles.length == 0 ? <></> : subTitles.map(
                    (item, index) => {
                      return (<track
                        kind="subtitles"
                        src={item.src}
                        srcLang={item.srclang}
                        label={item.label}
                      />)
                    }
                  )
                }
                {/* {
                  subTitles.length == 0 && subTitles.En && subTitles.En.length>0 ? <></> :
                   <track kind="captions" src={`${BASE_URL}${subTitles.En[0].src}?access_token=${getAccessToken()}`} srcLang={"en"} label="English"></track>
                } */}
              </ReactHlsPlayer>
            }
            <div className="grid grid-cols-7 px-5 gap-2  my-5">
               {video ? <WatchListButton video={video}></WatchListButton>:""}
              <SocialShareButton></SocialShareButton>
            </div>
            <Accordion collapseAll>
              <Accordion.Panel>
                <Accordion.Title>
                  {video?.title}
                  <span> {t("Episode")} {video?.format_original_number}</span>
                </Accordion.Title>
                <Accordion.Content>
                  <h1 className="mb-2 text-gray-800 leading-6">
                    {video?.title}
                  </h1>
                  <h2 className="mb-2 text-gray-500 leading-6">
                    {video?.description}
                  </h2>
                  <GroupTags tags_info={tags_info}></GroupTags>
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>
            <Accordion collapseAll>
              <Accordion.Panel>
                <Accordion.Title>
                  <div className="flex flex-wrap gap-5">
                    {t("Episodes")} ({episodes?.count})

                  </div>
                </Accordion.Title>
                <Accordion.Content>
                  <div className="mb-4">
                    <Select id="season" onChange={onChangeSeason} value={currentSeason}>
                      {seasons?.map((item, index) => {
                        return <option value={item} key={item.value} >Season {item}</option>
                      })
                      }
                    </Select>
                  </div>
                  <EpisodeList episodes={episodes?.results} selectedEpisodeNumber={video?.original_number} selectedSeason={video?.season} ></EpisodeList>
                  {episodes?.count > 0 ? <div className="flex overflow-x-auto sm:justify-center">
                    <Pagination layout="pagination" currentPage={currentPage} totalPages={Math.ceil(episodes?.count / 20)} onPageChange={onPageChange} showIcons />
                  </div> : ""
                  }
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>
            {video? <Discussion id={video.id} title={video.title} commentType={CommentTypes.Media}></Discussion> : "" }
          </div>
        </div>
        <div className="flex flex-col py-6 px-4 overflow-y-auto lg:w-[350px] xl:w-[400px]">

          {/* When relatedVideos is blank means data is not fetched yet meanwhile that time show Shimmer UI 
             when data fetching is done that means we get relatedVideos data and then show the data in the dom*/}
          {relatedVideos.length == 0
            ?
            ""
            : relatedVideos?.map((item, index) => {
              return <SuggestionVideoCard key={index} video={item} />;
            })}
        </div>
      </div>
    </div >
  );
};

export default VideoDetails;
