import {
    collection,
    addDoc,
} from "firebase/firestore";
import { db } from "../context/firebase";
import { genericDecryptedUsingAES256, genericEncryptedUsingAES256 } from "./api";
const usersCollectionRef = collection(db, "MediaActions");

export const getMediaActions = (action, noOfRecords) => {
    var allActions = getLocalStorateItem(action)
    if (allActions != null && allActions != undefined && allActions != "") {
        var records = JSON.parse(allActions)
        if (noOfRecords != 0)
            return records.slice(0, noOfRecords).reverse();
        else return records.reverse()
    }
    else return []
}

export const getLocalStorateItem = (action) => {
    var allActions = localStorage.getItem(action)
    if (allActions != null && allActions != undefined && allActions != "") {
        allActions = genericDecryptedUsingAES256(allActions)
        return allActions
    }
    return null
}

const setLocalStorageItem = (action, rawStr) => {
    var encrypted = genericEncryptedUsingAES256(rawStr)
    localStorage.setItem(action, encrypted)
}

export const removeAction = async (video, action) => {
    var allActions = getLocalStorateItem(action)
    if (allActions != null && allActions != "") {
        var allActionItemsItems = JSON.parse(allActions)
        var existingItems = allActionItemsItems.filter(s => s.id == video?.id)
        if (existingItems.length > 0) {
            const index = existingItems.indexOf(existingItems[0]);
            if (index > -1) { // only splice array when item is found
                allActionItemsItems.splice(index, 1); // 2nd parameter means remove one item only
            }
            setLocalStorageItem(action, JSON.stringify(allActionItemsItems))
        }
    }
};

export const addAction = async (video, action, enableFirestore) => {
    console.log('addAction', action)
    var allActions = getLocalStorateItem(action)
    var allActionItemsItems = []
    if (allActions == undefined || allActionItemsItems == null)
        allActionItemsItems = []
    else {
        allActionItemsItems = JSON.parse(allActions)
    }
    var existingItems = allActionItemsItems.filter(s => s.id == video?.id)
    if (existingItems.length > 0) {
        return
    }
    //for preventing slowing
    if (allActionItemsItems.length > 100) {
        allActionItemsItems.splice(0, 1)
    }
    allActionItemsItems.push(video)
    if (enableFirestore) {
        await addDoc(usersCollectionRef, {
            uniqueId: localStorage.getItem("UniqueId"),
            action: action,
            createdTime: new Date(),
            friendlyToken: video?.id,
            title: video?.title
        });
    }
    setLocalStorageItem(action, JSON.stringify(allActionItemsItems))
};
