import Episode from "./Episode"

const EpisodeList = ({ episodes, selectedEpisodeNumber, selectedSeason }) => {
    return <div className="flex flex-wrap gap-2">
        {episodes?.map((item, index) => {
            return <Episode episode={item} key={index} selectedEpisodeNumber={selectedEpisodeNumber} selectedSeason={selectedSeason}>
            </Episode>
        })}
    </div>
}

export default EpisodeList