import axios from "axios";
import * as CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';

export const BASE_URL = process.env.REACT_APP_BASE_API_URL

export const getAccessToken = () => {
  return Cookies.get("AccessToken")
}

const options = {
  headers: {
    "Authorization": `Bearer ${getAccessToken()}`,
  },
};

export const fetchDataFromApi = async (url) => {
  const result = await axios.get(`${BASE_URL}${url}`, options);
  var data = justTesting(result.data)
  return JSON.parse(data);
};

export const fetchRawData = async (url) => {
  const result = await axios.get(`${url}`);
  return result
};

export const cardThumbnail = (thumbnail_url, width = 352) => {
  if (thumbnail_url?.includes("ik.imagekit.io")) {
    thumbnail_url = thumbnail_url.replace("/phun73wiw/", `/phun73wiw/hurawatch/tr:w-${width}/`)
  }
  return thumbnail_url
}

export const getArrayQuery = (paramName, someArray) => {
  return someArray
    .map((x) => `${paramName}=${x}`)
    .join('&');
}

const justTesting = function (decString) {
  const key = CryptoJS.enc.Utf8.parse('4512631236589784');
  const iv = CryptoJS.enc.Utf8.parse('4512631236589784');
  var decrypted = CryptoJS.AES.decrypt(decString, key, {
    keySize: 128 / 8,
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  return decrypted.toString(CryptoJS.enc.Utf8)
}

const generickey = CryptoJS.enc.Utf8.parse('dbfddf0a-577e-474e-86bd-826778003754');
const genericiv = CryptoJS.enc.Utf8.parse('35a84eed-c709-4bb4-9300-22f9c9ac0bde');
export const genericEncryptedUsingAES256 = function (rawStr) {
  var encrypted = CryptoJS.AES.encrypt(rawStr, generickey, {
    keySize: 128 / 8,
    iv: genericiv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  return encrypted.toString()
}
export const genericDecryptedUsingAES256 = function (rawStr) {
  var encrypted = CryptoJS.AES.decrypt(rawStr, generickey, {
    keySize: 128 / 8,
    iv: genericiv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  return encrypted.toString(CryptoJS.enc.Utf8)
}

