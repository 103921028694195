import { useCallback, useEffect, useRef, useState } from "react";
import ScrollLoading from "./ScrollLoading";

const InifiniteScroll = ({ resultComponents, fetchSearchResults, nextAPIUrl }) => {

    const [isScrollLoading, setScrollIsLoading] = useState(false);
    const fetchDataCallBack = useCallback(async () => {
        if (isScrollLoading || nextAPIUrl == null || nextAPIUrl == '') return;
        setScrollIsLoading(true)
        await fetchSearchResults()
        setScrollIsLoading(false)
    }, [nextAPIUrl, isScrollLoading]);

    const loaderRef = useRef(null);
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            const target = entries[0];
            if (target.isIntersecting) {
                fetchDataCallBack();
            }
        });

        if (loaderRef.current) {
            observer.observe(loaderRef.current);
        }

        return () => {
            if (loaderRef.current) {
                observer.unobserve(loaderRef.current);
            }
        };
    }, [fetchDataCallBack]);

    return (
        <>
            {resultComponents}
            <div ref={loaderRef}>{isScrollLoading && <ScrollLoading />}</div>
        </>
    )
}

export default InifiniteScroll;