import React, { act, useContext, useEffect, useState } from "react";

import LeftNav from "../components/LeftNav";
import UseOnline from "../utils/UseOnline";
import OfflineComponent from "../shared/OfflineComponent";
import VideoListSlider from "../components/VideoListSlider";
import SliderTitle from "../components/SliderTitle";
import { SendGA } from "../utils/reactGAWrapper";
import { fetchDataFromApi } from "../utils/api";
import { FooterCategories } from "../components/FooterCategories";
import MyInfo from "../components/MyInfo";
import { MediaActions } from "../utils/constants";
import MediaCarousel from "../components/MediaCarousel";
import { useTranslation } from 'react-i18next';

const Feed = () => {
  const isOnline = UseOnline();
  const [genres, setGenres] = useState([])
  const [countries, setCountries] = useState([])
  const [year, setYears] = useState([])
  const [production, setProductions] = useState([])
  const [actor, setActors] = useState([])
  const { t, i18n } = useTranslation();

  useEffect(() => {
    document.getElementById("root").classList.remove("custom-h");
    fetchTags('Genre', 'Genre', setGenres)
    fetchTags('Country', 'Country', setCountries)
    fetchTags('Year', 'Year', setYears)
    fetchTags('Production', 'Production', setProductions)
    fetchTags('Actors', 'Actors', setActors)
    SendGA();
  }, []);

  const fetchTags = async (groupDisplay, label, setResult) => {
    const data = await fetchDataFromApi(`/api/v1/tags?=${1}&type=${groupDisplay}`);
    setResult(data?.results.map(s => s.title));
  };

  return (
    <div className="bg-white dark:bg-black ">
      <div className="flex">
        <LeftNav />

        {!isOnline && (
          <div className="grow w-[calc(100%-240px)] h-full overflow-y-auto">
            <OfflineComponent />
          </div>
        )}
        {isOnline && (
          <div className="grow w-[calc(100%-240px)] h-full overflow-y-auto pl-2">
            {/* When loading is true means data is not fetched yet meanwhile that time show Shimmer UI 
             when data fetching is done means loading is false and that means we get searchResults then show the data in the dom */}
            {/* <SliderTitle title={'Continue Watching'} url={`/favorite?t=Hollywood`}></SliderTitle> */}
            {/* <MediaCarousel></MediaCarousel> */}
            <SliderTitle title={t('Watch Your WatchList')} url={`/search?show=` + MediaActions.WatchList}></SliderTitle>
            <VideoListSlider key="yourFavorite" apiUrl={MediaActions.WatchList}></VideoListSlider>
            <SliderTitle title={t('Featured')} url={`/search?show=featured`}></SliderTitle>
            <VideoListSlider key="featured" apiUrl="/api/v1/search?show=featured&ordering=desc&sort_by=release_date"></VideoListSlider>
            <SliderTitle title={t('Explore Hollywood')} url={`/search?gtt=Hollywood`}></SliderTitle>
            <VideoListSlider key="hollywood" apiUrl="/api/v1/search?t=Hollywood"></VideoListSlider>
            <SliderTitle title={t('Watch New Movie')} url={`/search?gtt=Movie`}></SliderTitle>
            <VideoListSlider key="newMovie" apiUrl="/api/v1/search?t=Movie&ordering=desc&sort_by=add_date"></VideoListSlider>
            <SliderTitle title={t('Explore China')} url={`/search?gtc=China`}></SliderTitle>
            <VideoListSlider key="china" apiUrl="/api/v1/search?t=China&et=Anime"></VideoListSlider>
            <SliderTitle title={t('Korea Movie/Series')} url={`/search?gtc=South%20Korea`}></SliderTitle>
            <VideoListSlider key="korea" apiUrl="/api/v1/search?t=South%20Korea"></VideoListSlider>
            <SliderTitle title={t('Explore Anime')} url={`/search?gtt=Anime`}></SliderTitle>
            <VideoListSlider key="anime" apiUrl="/api/v1/search?t=Anime"></VideoListSlider>
            <SliderTitle title={t('Explore Adult Movie')} url={`/search?gtg=Adult`}></SliderTitle>
            <VideoListSlider key="adult" apiUrl="/api/v1/search?t=Adult"></VideoListSlider>
            <SliderTitle title={t('Latest Movie/Series')} url={`/search`}></SliderTitle>
            <VideoListSlider key="latest" apiUrl="/api/v1/media"></VideoListSlider>
          </div>
        )}
      </div>
      <footer class="mx-auto w-full max-w-container px-4 sm:px-6 lg:px-8 bg-white dark:bg-black" aria-labelledby="footer-heading">
        <div className="mb-16">
          {/* <h1 className="mt-10 text-black dark:text-white">{t("How To Watch Movies And Series On HuraWatch!")}</h1>
          <MyInfo></MyInfo> */}
          <div className="mt-10 grid grid-cols-2 gap-x-8 gap-y-10 lg:grid-cols-5">
            <FooterCategories label={t("Genres")} categories={genres} browseAllUrl={"/tags/genres"} />
            <FooterCategories label={t("Countries")} categories={countries} browseAllUrl={"/tags/countries"} />
            <FooterCategories label={t("Years")} categories={year} browseAllUrl={"/tags/years"} />
            <FooterCategories label={t("Productions")} categories={production} browseAllUrl={"/tags/productions"} />
            <FooterCategories label={t("Actors")} categories={actor} browseAllUrl={"/tags/actors"} />
          </div>
        </div>
        <div class="items-centers grid grid-cols-1 justify-between gap-4 py-6 md:grid-cols-2">
          <p class="text-sm/6 text-black dark:text-white max-md:text-center">© 2024 {t("2024 Hurawatch Asia. All rights reserved")}.</p>
          <div class="flex items-center justify-center space-x-4 text-sm/6 font-semibold text-black dark:text-white md:justify-end">
            <a href="/about-us">{t("About Us")}</a>
            <div class="h-4 w-px bg-gray-200"></div>
            <a href="/faq">FAQ</a></div></div>
      </footer>
    </div>)
};

export default Feed;
