import {
    collection,
    addDoc,
    getDocs,
    query,
    where,
    serverTimestamp,
    orderBy,
} from "firebase/firestore";
import { db, firebaseApp } from "../context/firebase";
const usersCollectionRef = collection(db, "Comments");

export const addComment = async (id, title, username, comment, commentType) => {
    await addDoc(usersCollectionRef, {
        uniqueId: localStorage.getItem("UniqueId"),
        title: title,
        id: id,
        createdTime: serverTimestamp(),
        username: username,
        comment: comment,
        commentType
    });
};

export const getComments = async (id) => {
    const q = query(
        collection(db, "Comments"),
        where("id", "==",id)
    ,orderBy('createdTime', 'desc'));

    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => doc.data())
}
