import React from "react";
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import { AppContext } from "./context/contextApi";

import Header from "./components/Header";
import Feed from "./pages/Feed";
import SearchResult from "./pages/SearchResult";
import VideoDetails from "./pages/VideoDetails";
import ErrorComponent from "./shared/ErrorComponent";
import ReactGA from 'react-ga4';
import {getSiteInfo, getGA4Tracking} from "./utils/localStorageWrapper";
import * as CryptoJS from 'crypto-js';
import { v4 as uuidv4 } from 'uuid';
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import './i18n';

const AppLayout = () => {
  TimeAgo.addLocale(en)
  TimeAgo.addDefaultLocale(en)
  ReactGA.initialize(getGA4Tracking() ?? getSiteInfo()?.REACT_APP_GA4);
  const uniqueId = localStorage.getItem('UniqueId')
  if (uniqueId == undefined)
    localStorage.setItem('UniqueId', uuidv4())
  return (
    <div>
      <Header />
      <Outlet />
    </div>
  );
};

const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <AppLayout />,
    errorElement: <ErrorComponent />,
    children: [
      { path: "/", element: <Feed /> },
      { path: "/search", element: <SearchResult /> },
      { path: "/view", element: <VideoDetails /> },
    ],
  },
]);

function App() {
  return (
    <AppContext>
      <div className="flex flex-col h-full">
        <RouterProvider router={appRouter} />
      </div>
    </AppContext>
  );
}

export default App;
