import React from "react";
import { abbreviateNumber } from "js-abbreviation-number";
import { Link } from "react-router-dom";
import { BsFillCheckCircleFill } from "react-icons/bs";
import VideoLength from "../shared/VideoLength";
import { cardThumbnail } from "../utils/api";
import { AsyncImage } from "../shared/AsyncImage";
import ReactTimeAgo from 'react-time-ago'

const VideoCard = ({ video, isFixedSize }) => {
  const styleWidth = isFixedSize ? "w-48":""
  const thumbnail_url = cardThumbnail(video?.thumbnail_url, isFixedSize ? 450:452)
  return (
    <Link to={`${video.url}`}>
      <div className={`flex flex-col mb-8 ${styleWidth}`}>
        <div className="relative h-48 md:h-40 md:rounded-xl overflow-hidden">
          <AsyncImage src={thumbnail_url} alt="thumbnails" className="h-full w-full object-cover"></AsyncImage>
          {video?.format_latest_episode && <VideoLength time={video?.format_latest_episode} />}
        </div>

      <div className="flex text-black dark:text-white mt-3">
          {/* <div className="flex items-start">
            <div className="flex h-9 w-9 rounded-full overflow-hidden">
              <img
                src={video?.author?.avatar[0]?.url}
                alt="Avatar"
                className="h-full w-full object-cover"
              />
            </div>
          </div> */}

          <div className="flex flex-col ml-3 overflow-hidden">
            <h5  className="text-sm font-bold line-clamp-2">
              {video?.title}
            </h5 >

            {/* <span className="text-[12px] font-semibold mt-2 text-black/[0.7] dark:text-white/[0.7] flex items-center">
              {video?.author?.title}
              {video?.author?.badges[0]?.type === "VERIFIED_CHANNEL" && (
                <BsFillCheckCircleFill className="text-black/[0.7] dark:text-white/[0.5] text-[12px] ml-1" />
              )}
            </span>

            <div className="flex text-[12px] font-semibold text-black/[0.7] dark:text-white/[0.7] truncate overflow-hidden">
              <span>{`${abbreviateNumber(video?.views, 2)} views`}</span>
              <span className="flex text-[24px] text-black/[0.7] dark:text-white/[0.7] font-bold leading-none relative top-[-10px] mx-1">
                .
              </span>
              <span className="truncate">{video?.publishedTimeText}</span>
            </div> */}
              <div className="flex text-[12px] font-semibold text-black/[0.7] dark:text-white/[0.7] truncate overflow-hidden">
              <span>{`${abbreviateNumber(video?.views??0, 2)} views`}</span>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default VideoCard;
