import { Dropdown } from "flowbite-react";

const LanguageDropdown = () => {
    const languages = [
        { code: "en", label: "English", url :"https://hurawatch.asia" },
        { code: "km", label: "ភាសាខ្មែរ" , url :"https://km.hurawatch.asia"},
        { code: "es", label: "Español" , url :"https://es.hurawatch.asia"},
        { code: "fr", label: "Français" , url :"https://fr.hurawatch.asia"},
        { code: "de", label: "Deutsch" , url :"https://de.hurawatch.asia"},
        { code: "it", label: "Italiano" , url :"https://it.hurawatch.asia"},
        { code: "ja", label: "日本語" , url :"https://ja.hurawatch.asia"},
    ]
    const selectedLanguage = languages.filter(s=>s.code == localStorage.getItem('language')??"en")
    return (
        <Dropdown label={selectedLanguage[0].label} dismissOnClick={true}>
            {languages.map(s => {
                return (
                    <Dropdown.Item key={s.code} value={s.code}><a href={s.url} title={s.label}>{s.label}</a></Dropdown.Item>
                )
            })}
        </Dropdown>
    )
}

export default LanguageDropdown