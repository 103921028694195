import { Accordion } from "flowbite-react";
import { addComment, getComments } from "../utils/CommentUtil";
import { useEffect, useState } from "react";
import ReactTimeAgo from 'react-time-ago'
import { Timestamp } from "firebase/firestore";
import { useTranslation } from "react-i18next";

const Discussion = ({ id, title, commentType }) => {
  const [username, setUsername] = useState("")
  const [comment, setComment] = useState("")
  const [comments, setComments] = useState([])
  const { t } = useTranslation();

  const onSubmitComment = async (event) => {
    event.preventDefault();
    if (username == null || comment == null || username == '' || comment == '') {
      alert("Please Input username and comment")
      return
    }
    setComment("")
    setUsername("")
    addComment(id, title, username, comment, commentType)
    fetchData()
  }

  async function fetchData() {
    setComments(await getComments(id))
  }

  useEffect(() => {
    fetchData();
  }, [id])
  return (<Accordion collapseAll>
    <Accordion.Panel>
      <Accordion.Title>
        <p className="text-lg lg:text-2xl font-bold text-gray-900 dark:text-white">Discussion ({comments.length})</p>
      </Accordion.Title>
      <Accordion.Content>
        <div className="mx-auto px-4">
          <form className="mb-6" onSubmit={onSubmitComment} >
            <div className="py-2 px-4 mb-4 bg-white rounded-lg rounded-t-lg border border-gray-200 dark:bg-gray-800 dark:border-gray-700">
              <label htmlFor="name" className="sr-only">Your Alias Name</label>
              <input type="text" id="name" rows="6"
                className="px-0 w-full text-sm text-gray-900 border-0 focus:ring-0 focus:outline-none dark:text-white dark:placeholder-gray-400 dark:bg-gray-800"
                placeholder={t("Write your Name...")} required
                value={username}
                onChange={e => setUsername(e.target.value)}
              />
            </div>
            <div className="py-2 px-4 mb-4 bg-white rounded-lg rounded-t-lg border border-gray-200 dark:bg-gray-800 dark:border-gray-700">
              <label htmlFor="comment" className="sr-only">Your comment</label>
              <textarea id="comment" rows="6"
                className="px-0 w-full text-sm text-gray-900 border-0 focus:ring-0 focus:outline-none dark:text-white dark:placeholder-gray-400 dark:bg-gray-800"
                placeholder={t("Write a comment...")} required
                value={comment}
                onChange={e => setComment(e.target.value)}
              ></textarea>
            </div>
            <input type="submit" value={t("Post Comment")}
              className="inline-flex items-center py-2.5 px-4 text-xs font-medium text-center text-white bg-primary-700 rounded-lg focus:ring-4 focus:ring-primary-200 dark:focus:ring-primary-900 hover:bg-primary-800" />
          </form>
          {
            comments.length == 0 ? "" : comments.map((val, index) => {
              var date = val?.createdTime == null ? null : new Date(val?.createdTime?.toDate())
              return (
                <article key={index} className="p-6 text-base bg-white rounded-lg dark:bg-gray-900">
                  <footer className="flex justify-between items-center mb-2">
                    <div className="flex items-center">
                      <p className="inline-flex items-center mr-3 text-sm text-gray-900 dark:text-white font-semibold">{val?.username}</p>
                      {date == null ? "" :<p className="text-sm text-gray-600 dark:text-gray-400"><ReactTimeAgo date={date} locale="en-US"/></p>}
                    </div>
                  </footer>
                  <p className="text-gray-500 dark:text-gray-400">{val.comment}</p>
                </article>
              )
            })
          }

        </div>
      </Accordion.Content>
    </Accordion.Panel>
  </Accordion>)
}
export default Discussion