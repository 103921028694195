import { Link } from "react-router-dom"

const Episode = ({ episode, selectedEpisodeNumber, selectedSeason }) => {
    return <div>
        {
            selectedEpisodeNumber == episode?.original_number && selectedSeason == episode?.season ?
                <button id={`EP-${episode}`} title={`EP-${episode}`} type="button" class="text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">
                    {episode?.format_original_number}
                </button> :
                <Link to={episode?.url} className="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800">
                    <span class="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                        {episode?.format_original_number}
                    </span>
                </Link>
        }

    </div>
}

export default Episode