import React from "react";
import { abbreviateNumber } from "js-abbreviation-number";
import { Link } from "react-router-dom";
import { BsFillCheckCircleFill } from "react-icons/bs";
import VideoLength from "../shared/VideoLength";
import { cardThumbnail } from "../utils/api";

const SuggestionVideoCard = ({ video }) => {
  const thumbnail_url = cardThumbnail(video?.thumbnail_url,168)
  return (
    <Link to={`${video.url}`}>
      <div className="flex mb-4">
        <div className="relative h-24 lg:h-20 xl:h-24 w-40 min-w-[168px] lg:w-32 lg:min-w-[128px] xl:w-40 xl:min-w-[168px] rounded-xl bg-slate-800 overflow-hidden">
          <img
            src={thumbnail_url}
            alt="thumbnails"
            className="h-full w-full object-cover"
          />
          {video?.format_latest_episode && <VideoLength time={video?.format_latest_episode} />}
        </div>

        <div className="flex text-black dark:text-white">
          {/* <div className="flex items-start">
            <div className="flex h-9 w-9 rounded-full overflow-hidden">
              <img
                src={video?.author?.avatar[0]?.url}
                alt="Avatar"
                className="h-full w-full object-cover"
              />
            </div>
          </div> */}

          <div className="flex flex-col ml-3 overflow-hidden">
            <span className="text-sm font-bold line-clamp-2 lg:text-sm xl:text-sm ">
              {video?.title}
            </span>
          </div>
          
        </div>
      </div>
    </Link>
  );
};

export default SuggestionVideoCard;
