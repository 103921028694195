import Tag from "./Tag"

const GroupTags = ({ tags_info }) => {
    return <div className="grid-cols-2 gap-6 grid">
        {tags_info?.map((item, index) => {
            return <div key={index + item}>
                <p className="text-blue-800 font-medium">
                    {item[0].group_display}
                </p>
                <div className="mb-2 flex flex-wrap gap-3 items-center my-3">
                    {item?.map((tag, index) => {
                        return <Tag key={index} tag={tag} index={index}></Tag>
                    })}
                </div>
            </div>
        })}
    </div>
}

export default GroupTags