
import {
    EmailShareButton,
    FacebookShareButton,
    RedditShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    FacebookMessengerShareButton,
} from "react-share";
import {
    EmailIcon,
    FacebookIcon,
    FacebookMessengerIcon,
    RedditIcon,
    TelegramIcon,
    TwitterIcon,
    WhatsappIcon,
} from "react-share";
import { SendNewActionGA } from "../utils/reactGAWrapper";

const SocialShareButton = () => {
    const beforeOnClick = (social) => {
        // SendNewActionGA(social, 'Click')
    }
    return(<>
        <FacebookShareButton url={window.location.href} onShareWindowClose={beforeOnClick('FacebookShareButton')}>
            <FacebookIcon size={40} round={true}></FacebookIcon>
        </FacebookShareButton>
        {/* <FacebookMessengerShareButton url={window.location.href} beforeOnClick ={beforeOnClick('FacebookMessengerShareButton')}>
            <FacebookMessengerIcon size={64} round={true}></FacebookMessengerIcon>
        </FacebookMessengerShareButton> */}
        <TwitterShareButton url={window.location.href} onShareWindowClose={beforeOnClick('TwitterShareButton')}>
            <TwitterIcon size={40} round={true}></TwitterIcon>
        </TwitterShareButton>
        <RedditShareButton url={window.location.href} onShareWindowClose={beforeOnClick('RedditShareButton')}>
            <RedditIcon size={40} round={true}></RedditIcon>
        </RedditShareButton>
        <TelegramShareButton url={window.location.href} onShareWindowClose={beforeOnClick('TelegramShareButton')}>
            <TelegramIcon size={40} round={true}></TelegramIcon>
        </TelegramShareButton>
        <WhatsappShareButton url={window.location.href} onShareWindowClose={beforeOnClick('WhatsappShareButton')}>
            <WhatsappIcon size={40} round={true}></WhatsappIcon>
        </WhatsappShareButton>
        {/* <EmailShareButton url={window.location.href} beforeOnClick ={beforeOnClick('EmailShareButton')}>
            <EmailIcon size={64} round={true}></EmailIcon>
        </EmailShareButton> */}
    </>)
        
}

export default SocialShareButton;