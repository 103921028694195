import { useEffect } from "react";
import { useTranslation } from 'react-i18next';

export function FooterCategories({ label, categories, browseAllUrl }) {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <h3 className="text-sm/6 font-semibold text-black dark:text-white">
        {label}
      </h3>
      <ul role="list" className="mt-4 space-y-4">
        {
          categories.length == 0 ? "" : categories.map(d => (
            <li class="group">
              <a href={"/search?t=" + d} class="text-sm/6 text-black dark:text-white hover:text-gray-900 ">
                {t("Browse")} {d}
              </a></li>
          ))
        }
      </ul>
    </div>
  )
}
