import React, { useContext, useEffect, useState } from "react";

import VideoCard from "../components/VideoCard";
import UseOnline from "../utils/UseOnline";
import ShimmerVideoCard from "../shared/ShimmerVideoCard";
import { fetchDataFromApi } from "../utils/api";
import { DataContext } from "../context/contextApi";
import { AllMediaActions, MediaActions } from "../utils/constants";
import { getMediaActions } from "../utils/MediaActionUtil";

const VideoListSlider = ({apiUrl}) => {
    const [result, setResult] = useState("");
    const { setLoading } = useContext(DataContext);


    useEffect(() => {
        fetchSearchResults(apiUrl);
    }, []);

    const fetchSearchResults = async (url) => {
        if(AllMediaActions.some(s=>s==url))
        {
            setResult(getMediaActions(url,20, true))
        }
        else{
            setLoading(true)
            const data = await fetchDataFromApi(url);
            setResult(data.results);
            setLoading(false)
        }
    };

    return (
        AllMediaActions.some(s=>s==apiUrl) && result == "" ? "":(
        result == ""
            ? <div className="relative w-full flex gap-6 snap-x snap-mandatory overflow-x-auto pt-5 pr-5">{
                Array(7)
                    .fill("")
                    .map((e, index) => {
                        return <ShimmerVideoCard key={index}  isFixedSize={true} />;
                    })}
            </div>
            :
            (
                <div className="relative w-full flex gap-6 snap-x snap-mandatory overflow-x-auto pt-5 pr-5">
                    {result.map((item, index) => (
                        <div className="snap-always snap-center">
                            <VideoCard key={index} video={item} isFixedSize={true} />
                        </div>
                    ))}
                </div>
            ))
    );
};

export default VideoListSlider;
