
import { useEffect, useState } from "react";
import { MediaActions } from "../utils/constants";
import { addAction, getLocalStorateItem, removeAction } from "../utils/MediaActionUtil";
import { useTranslation } from "react-i18next";

const WatchListButton = ({ video }) => {
    const { t } = useTranslation();
    const [isWatchedList, setIsWatchedList] = useState(false);
    const addWatchListAction = async () => {
        addAction(video, MediaActions.WatchList, true)
        setIsWatchedList(true)
    };
    const removeWatchListAction = async () => {
        removeAction(video, MediaActions.WatchList)
        setIsWatchedList(false)
    };

    useEffect(() => {
        var allWatchLists = getLocalStorateItem(MediaActions.WatchList)
        if (allWatchLists != undefined && allWatchLists != null) {
            var allWatchListItems = JSON.parse(allWatchLists)
            var existingItems = allWatchListItems.filter(s => s.id == video?.id)
            setIsWatchedList(existingItems.length > 0)
        }
    }, [video?.id])
    return (
        isWatchedList ?
            <button onClick={removeWatchListAction} type="button" class="text-white bg-blue-700  hover:bg-white hover:text-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 inline-flex  text-center items-center dark:border-blue-500 dark:text-white dark:hover:bg-white dark:focus:ring-blue-800 dark:hover:text-blue-500">
                <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 18">
                    <path d="M3 7H1a1 1 0 0 0-1 1v8a2 2 0 0 0 4 0V8a1 1 0 0 0-1-1Zm12.954 0H12l1.558-4.5a1.778 1.778 0 0 0-3.331-1.06A24.859 24.859 0 0 1 6 6.8v9.586h.114C8.223 16.969 11.015 18 13.6 18c1.4 0 1.592-.526 1.88-1.317l2.354-7A2 2 0 0 0 15.954 7Z" />
                </svg>
                <span className="px-1.5 hidden md:block">
                    {t("Saved")}
                </span>
            </button> :
            <button onClick={addWatchListAction} type="button" class="text-blue-700 border border-blue-700 hover:bg-blue-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 inline-flex  text-center items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 dark:hover:bg-blue-500">
                <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 18">
                    <path d="M3 7H1a1 1 0 0 0-1 1v8a2 2 0 0 0 4 0V8a1 1 0 0 0-1-1Zm12.954 0H12l1.558-4.5a1.778 1.778 0 0 0-3.331-1.06A24.859 24.859 0 0 1 6 6.8v9.586h.114C8.223 16.969 11.015 18 13.6 18c1.4 0 1.592-.526 1.88-1.317l2.354-7A2 2 0 0 0 15.954 7Z" />
                </svg>
                <span className="px-1.5 hidden md:block">
                    {t("WatchList")}
                </span>
            </button>
    )
}
export default WatchListButton