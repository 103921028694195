import { Button } from "flowbite-react"
import { HiOutlineArrowRight } from "react-icons/hi"
import { Link } from "react-router-dom"

const SliderTitle = ({ title, url }) => {
    return (<div className="flex flex-wrap gap-2 mt-5">
        <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            <Link to={url}>
                {title}
            </Link>
        </h5>
        {url == null ? "" :
            <Link to={url}>
                <Button outline pill id={`btn-${title}`} title={`btn-${title}`}>
                    <HiOutlineArrowRight className="h-6 w-6" />
                </Button>
            </Link>
        }
    </div>)
}

export default SliderTitle